import classNames from 'classnames';

import { ReactComponent as Arrow } from '../../sharedImages/Arrow.svg';
import { ReactComponent as ComentatorIcon } from './images/Comentator_icon.svg';
import { ReactComponent as DesignIcon } from './images/design_icon.svg';
import { ReactComponent as DJIcon } from './images/DJ_icon.svg';
import { ReactComponent as HostIcon } from './images/Host_icon.svg';
import { ReactComponent as JudgeIcon } from './images/Judge_icon.svg';
import { ReactComponent as LandingIcon } from './images/Landing_icon.svg';
import { ReactComponent as PhotoIcon } from './images/Photo_icon.svg';
import ball1 from './images/photos/ball1.jpg';
import ball2 from './images/photos/ball2.jpg';
import ball3 from './images/photos/ball3.jpg';
import ball4 from './images/photos/ball4.jpg';
import DJ2 from './images/photos/DJ2.jpg';
import DJ3 from './images/photos/DJ3.jpg';
import DJ4 from './images/photos/DJ4.jpg';
import DJ5 from './images/photos/DJ5.jpg';
import DJ6 from './images/photos/DJ6.jpg';
import Photogapher1 from './images/photos/ewq1.jpg';
import Photogapher2 from './images/photos/ewq2.jpg';
import host3 from './images/photos/host3.jpg';
import judge12 from './images/photos/judge12.jpg';
import judge14 from './images/photos/judge14.jpg';
import judge2 from './images/photos/judge2.jpg';
import judge9 from './images/photos/judge9.jpg';
import Komment1 from './images/photos/komment1.jpg';
import Komment2 from './images/photos/komment2.jpg';
import Komment3 from './images/photos/komment3.jpg';
import Komment4 from './images/photos/komment4.jpg';
import Photo2 from './images/photos/qwe2.png';
import Photo3 from './images/photos/qwe3.png';
import TV1 from './images/photos/TV1.jpg';
import TV2 from './images/photos/TV2.jpg';
import TV3 from './images/photos/TV3.jpg';
import TV5 from './images/photos/TV5.jpg';
import Vedushiy0 from './images/photos/vedushiy0.jpg';
import Vedushiy1 from './images/photos/vedushiy1.jpg';
import Vedushiy2 from './images/photos/vegushiy2.jpg';
import Videograhper0 from './images/photos/Videograhper0.jpg';
import Videograhper3 from './images/photos/Videograhper3.jpg';
import Videograhper5 from './images/photos/Videograhper5.jpg';
import Videograhper6 from './images/photos/Videograhper6.jpg';
import { ReactComponent as SportInventoryIcon } from './images/Sport-inventory_icon.svg';
import { ReactComponent as TrainingIcon } from './images/training_icon.svg';
import { ReactComponent as VideoIcon } from './images/Video_icon.svg';
import s from './Service.module.scss';

export interface CardServices {
    title: string;
    icon: JSX.Element;
    blocks: {
        title: string;
        content: JSX.Element;
        pics?: string[];
    }[];
}

export const cards: CardServices[] = [
    {
        title: 'Спортивная фотосъемка',
        icon: <PhotoIcon />,
        blocks: [
            {
                title: '',
                pics: [Photogapher1, Photo2, Photogapher2, Photo3],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Спортивная съемка не зря считается высшим пилотажем в фотоискусстве. Ведь она требует предельной концентрации внимания и
                            скорости реакции фотографа, его насмотренности и хорошего вкуса.
                            <br />
                            <br />В одном кадре должны быть сосредоточены сила спортсмена, драйв движения, эмоции и напряжение происходящего на поле.
                            Талантливый автор может показать всю история состязания даже через небольшой фоторепортаж.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Фотосъемка
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>от 3 000 ₽/час</span>
                                </span>
                            </li>
                        </ul>

                    </div>
                ),
            },
        ],
    },
    {
        title: 'Видеосъемка и монтаж ролика',
        icon: <VideoIcon />,
        blocks: [
            {
                title: '',
                pics: [Videograhper0, Videograhper3, Videograhper5, Videograhper6],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Видео с мероприятия поможет ещё раз пережить все яркие моменты прошедшего матча, поделиться ими с родными и близкими.
                            Качественный видеоролик можно выложить на сайт вашей компании и в соцсети, в корпоративный Youtube или Telegram-канал.
                            Вы сможете показать жизнь своего коллектива изнутри потенциальным соискателям на вакансии и партнёрам по бизнесу. Кроме
                            того, такие съемки станут динамичным дополнением к имиджевому ролику или презентации о вашей компании. 
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Видеосъемка (1 оператор и 1 камера)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>от 20 000 ₽/день</span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Видеосъемка (1 оператор и 1 камера) + монтаж ролика (от 1 до 5 мин)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>от 50 000 ₽/день</span>
                                </span>
                            </li>
                        </ul>

                    </div>
                ),
            },
        ],
    },
    {
        title: 'АРЕНДА ЗВУКОВОГО + TV ОБОРУДОВАНИЯ',
        icon: <DJIcon />,
        blocks: [
            {
                title: 'Звуковое оборудование',
                pics: [DJ2, DJ3, DJ4, DJ5],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Чистый звук и мощные вибрации помогут создать атмосферу настоящего соревнования, помогая сердца болельщиков биться в едином ритме.
                            Мы провели сотни спортивных мероприятий и точно знаем, какой сетап оборудования подойдет именно для вашего. Мы заранее привезем его,
                            подключим и протестируем на площадке, а после окончания события самостоятельно демонтируем и увезем. 
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            ЗВУКОВОЕ ОБОРУДОВАНИЕ
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Колонки: Electro-Voice ELX115P{' '}
                                <span className={s.colorAccent}>/</span> Behringer B212D
                            </li>
                            <li className={s.listItem}>
                                Микшер: Yamaha MG12XU <span className={s.colorAccent}>/</span>{' '}
                                SOUNDCRAFT EPM8 PAW5000+PAH1300
                            </li>
                            <li className={s.listItem}>
                                Микрофоны: PASGAO <span className={s.colorAccent}>/</span>{' '}
                                Sennheiser ew 100 g3
                            </li>
                            <li className={s.listItem}>
                                Вся необходимая коммутация
                            </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды комплектов:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Микшер + 2 колонки + 2 стойки + 2 микрофона + коммутация
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>от 15 000 ₽/день (далее 7 500 ₽/день)</span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Микшер + 4 колонки + 4 стойки + 4 микрофона + коммутация
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>от 20 000 ₽/день (далее 9 500 ₽/день)</span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Доставка, монтаж, демонтаж и забор
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>5 000 ₽</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
            {
                title: 'TV оборудование',
                pics: [TV1, TV2, TV3, TV5],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            На улице или в зале, вам понадобится большой экран для показа болельщикам и гостям соревнований турнирных таблиц, рекламных роликов и
                            другой информации. Наши LCD-панели 55” дают сочную и чёткую картинку как под прямыми солнечными лучами, так и в помещении с искусственным
                            освещением.
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            TV оборудование
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Samsung DH55E:  55' , TFT LCD, 1920x1080, 6 мс, 700 кд/м², HDMI, VGA, DP, USB 2.0, SPK{' '}
                            </li>
                            <li className={s.listItem}>
                                Напольная передвижная стойка для TV{' '}
                            </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Стоимость аренды
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>7 500 ₽/день (далее 5 000 ₽/день)</span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Доставка, монтаж, демонтаж и забор
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>5 000 ₽</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'АРЕНДА СПОРТИВНОГО ИНВЕНТАРЯ',
        icon: <SportInventoryIcon />,
        blocks: [
            {
                title: 'Мячи',
                pics: [ball1, ball2, ball3, ball4],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Матч - это море драйва, буря эмоций, безудержная радость или горечь от каждого гола, слэм-данка или очка. Такие моменты объединяют как игроков, так
                            и болельщиков. У нас есть всё, чтобы ваш дружеский матч состоялся, в том числе - профессиональные мячи. 
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            Мячи
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Футбольный (мини-футбольный, размер 4-ка) : Select Super League АМФР
                                РФС{' '}
                            </li>
                            <li className={s.listItem}>
                                Баскетбольный: Wilson VTB Sibur Gameball ECO{' '}
                            </li>
                            <li className={s.listItem}>
                                Волейбольный (для пляжного волейбола): Mikasa V200W{' '}
                            </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>1 мяч</span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        800 ₽/день (далее 300 ₽/день)
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
            {
                title: 'Разметка',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Не всегда в городе есть площадка именно для вашего вида спорта. Например, игроки в мини-футбол часто вынуждены снимать большое поле. Чтобы разбить его на
                            4 четверти, и при этом разметка выглядела аккуратно, понадобятся специальные фишки. Их вы можете арендовать у нас. 
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            Разметка
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Фишки для разметки поля силиконовые плоские SEVEN (4х цветов){' '}
                            </li>
                            <li className={s.listItem}>
                                Фишки для разметки поля стандартные SEVEN{' '}
                            </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Набор фишек (24 шт.)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        500 ₽/день (далее 250 ₽/день)
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
            {
                title: 'Манишки',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Какая же команда - и без формы? Конечно, можно изготовить мерч специально для соревнований. Но есть и более доступная альтернатива. Это специальные тканевые
                            манишки, которые надевают поверх футболки, толстовки или ветровки. Или поверх спортивной формы, если вдруг у игроков из разных команд совпали цвета одежды. 
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            Манишки
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Манишка тренировочная SEVEN VE001 (салатовая и оранжевая){' '}
                            </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Набор манишек (10 шт.)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        1 200 ₽/день (далее 250 ₽/день)
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
            {
                title: 'Шатер',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Ни одно уличное мероприятие не обойдется без шатров. Они защитят от солнца, ветра и осадков. А еще помогут оформить точки выдачи манишек, воды, заполнения анкет участников и пр.
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            Шатер
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>ПРОФИ Тент 3,0 х 4,5 (зеленый)</li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>Один шатер</span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        10 000 ₽/день (далее 5 000 ₽/день)
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
            {
                title: 'Флаги',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            У вас есть флаг вашей команды или компании? А нас есть, куда его поставить и закрепить. Красиво развевающийся на ветру флаг - неотъемлемая часть уличных соревнований.
                            А если речь о помещении, его можно красиво закрепить. Всё необходимое для этого вы можете арендовать у нас. 
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            Флаги
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                Флагштоки классический “Виндер” высота 3,1 метра{' '}
                            </li>
                        </ul>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>Основание для флага типа "Штырь" </li>
                        </ul>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>Основание для флага типа "Балласт" </li>
                        </ul>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>Основание для флага типа "Трио" </li>
                        </ul>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость аренды:</span>
                        </div>
                        <ul className={classNames(s.boldAccent, s.list)}>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Комплект (2 флагштока + 2 основания)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        2 500 ₽/день (далее 500 ₽/день)
                                    </span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Комплект (4 флагштока + 4 основания)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        3 500 ₽/день (далее 1 000 ₽/день)
                                    </span>
                                </span>
                            </li>
                            <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    Комплект (8 флагштока + 8 оснований)
                                </span>
                                {' = '}
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        6 500 ₽/день (далее 2 000 ₽/день)
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'Тренировки',
        icon: <TrainingIcon />,
        blocks: [
            {
                title: '',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Для вас главное не просто участие, а победа? Сделайте максимум, чтобы увеличить шансы своей команды! Тренировки с профессиональными наставниками
                            помогут прийти в форму, разогреться и добиться наилучших результатов. Кроме того, это возможность для ваших сотрудников интересно провести время
                            вместе и сплотиться.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость </span>
                            <span className={s.colorAccent}>
                                    по запросу
                                </span>
                                <br />
                                <br />
                        </div>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'РАЗРАБОТКА ЛЕНДИНГА',
        icon: <LandingIcon />,
        blocks: [
            {
                title: '',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Чтобы вашим сотрудникам, партнёрам и гостям было проще узнать всё важное о вашем спортивном
                            празднике, можно создать специальный сайт - лендинг. Мы обсудим все детали, учтём ваши пожелания,
                            чтобы сверстать информативный, яркий и удобный лендинг в вашем фирменном стиле.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость:</span>
                        </div>
                        <li className={s.listItem}>
                            <span className={s.boldAccent}>
                                Создание одностраничного лендинга
                            </span>
                            {' = '}
                            <span className={s.boldAccent}>
                                <span className={s.colorAccent}>
                                    от 75 000 ₽
                                </span>
                            </span>
                        </li>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'Графическое оформление',
        icon: <DesignIcon />,
        blocks: [
            {
                title: '',
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                        Первое, на что обращают внимание участники и болельщики соревнований по прибытию на площадку – это её визуальное оформление.
                        Доверьте эту задачу тем, кто сможет создать для вас что-то уникальное, яркое и презентабельное.
                        <br />
                        <br />В нашей команде работают опытные дизайнеры, профессионалы своего дела. Их опыт, вкус и насмотренность позволят
                        реализовать ваши идеи в лучшем виде. Нет идей? Мы предложим свои на основе ваших предпочтений.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость:</span>
                        </div>
                        <li className={s.listItem}>
                                <span className={s.boldAccent}>
                                    <span className={s.colorAccent}>
                                        от 35 000 ₽
                                    </span>
                                </span>
                            </li>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'Спортивные комментаторы',
        icon: <ComentatorIcon />,
        blocks: [
            {
                title: '',
                pics: [Komment1, Komment2, Komment3, Komment4],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                        Когда вы смотрите футбол или хоккей, турнир по волейболу или слалом, за кадром работает комментатор.
                        Он не только помогает разобраться в происходящем, но и своими эмоциями заряжает вас! Вы можете нанять проф. комментаторов,
                        тогда ваши корпоративные соревнования не будут ничем уступать городским матчам.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость </span>
                            <span className={s.colorAccent}>
                                    по запросу
                                </span>
                                <br />
                                <br />
                        </div>
                        <li className={s.listItem}>
                            <span className={s.boldAccent}>
                                
                            </span>
                        </li>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'Судьи',
        icon: <JudgeIcon />,
        blocks: [
            {
                title: '',
                pics: [judge2, judge12, judge9, judge14],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Еще на этапе планирования спортивного события важно разработать правила, составить турнирную сетку и определить четкий тайминг.
                            А в процессе кто-то должен следить за соблюдением этих правил и считать очки, чтобы честно присвоить победу сильнейшим игрокам
                            или команде. Для этого вы можете нанять наших профессиональных судей.
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость </span>
                            <span className={s.colorAccent}>
                                    по запросу
                                </span>
                                <br />
                                <br />
                        </div>
                    </div>
                ),
            },
        ],
    },
    {
        title: 'Ведущие & DJ',
        icon: <HostIcon />,
        blocks: [
            {
                title: 'Ведущие',
                pics: [Vedushiy0, Vedushiy1, Vedushiy2,  host3],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Спартакиада, эстафета или чемпионат превратятся в балаган или праздное столпотворение, если не пригласить ведущего.
                            Он будет взаимодействовать с аудиторией, поддерживать боевой настрой и координировать происходящее, чтобы всё шло по плану.
                            От профессиональных качеств специалиста многое зависит. Мы подберём для вашего мероприятия самого лучшего, харизматичного и опытного ведущего.
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость </span>
                            <span className={s.colorAccent}>
                                    по запросу
                                </span>
                                <br />
                                <br />
                        </div>
                    </div>
                ),
            },
            {
                title: 'DJ',
                pics: [DJ2, DJ3, DJ5, DJ6],
                content: (
                    <div className={s.contentWrapper}>
                        <div className={classNames(s.boldAccent, s.block)}>
                            Музыка поможет придать драйва и . Популярные композиции в трендовых аранжировках от топовых диджеев прозвучат для вас и гостей вашего события.
                        </div>
                        <div
                            className={classNames(s.headingText, s.colorAccent)}
                            style={{ marginBottom: 12 }}
                        >
                            
                        </div>
                        <div className={s.rowBlock}>
                            <div className={s.divider} style={{ marginRight: 10 }} />
                            <a
                                href="https://formsadd.worksportbalance.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={s.link}
                            >
                                заказать услугу <Arrow className={s.arrow} />
                            </a>
                        </div>
                        <div className={s.priceRow}>
                            <span className={s.boldAccent}>Стоимость </span>
                            <span className={s.colorAccent}>
                                    по запросу
                                </span>
                                <br />
                                <br />
                        </div>
                    </div>
                ),
            },
        ],
    },
];
