import classNames from "classnames";

import { ReactComponent as TgIcon } from "../../sharedImages/telegram_icon.svg";
import { ReactComponent as YoutubeIcon } from "../../sharedImages/youtube_icon.svg";
import s from "./Contacts.module.scss";


export default function Contacts() {
  return (
    <section className={classNames("section", s.contactsSection)} id="contacts">
      <div className="wrapper">
        <div className={s.contacts}>
          <ul className={s.contacts__list}>
            <li className={s.contacts__item}>
              <div className={s.contacts__logo}>
                <a
                  href="https://it-events.com/company"
                  className={s.contacts__link}
                  target=" _blank"
                >
                  <img
                    src="https://it-events.com/system/attachments/files/000/002/048/original/it-events_white.svg"
                    alt=""
                    className={s.contacts__img}
                  />
                </a>
              </div>
              <p className={s.contact__desc}>Россия. Санкт-Петербург</p>
              <p className="contact__desc contact__desc--font-normal">
                IT-Events, LTD
              </p>
              <p className={s.contact__name}>
                Торжковская улица, д.5, офис 37
              </p>
              <a href="tel:+79216457808" className={s.contact__tel}>
                +7-921-645-78-08
              </a>
              <a href="mailto:ru@it-events.com" className={s.contact__email}>
                ru@it-events.com
              </a>
            </li>

            <li className={s.contacts__item}>
              <p className={s.contact__desc}>Армения. Ереван</p>
              <p className="contact__desc contact__desc--font-normal">
                ИП Каланов Денис
              </p>
              <p className={s.contact__name}>
                Проспект Комитаса, д.35, офис 14
              </p>
              <a href="tel:+37499187808" className={s.contact__tel}>
                +374-99-187808
              </a>
              <a href="mailto:am@it-events.com" className={s.contact__email}>
                am@it-events.com
              </a>
            </li>

            <li className={s.contacts__item}>
              <ul className={s.contacts__social}>
                <li className={classNames(s.socials__item, s.socials__itemYb)}>
                  <a
                    className={s.socials__link}
                    href="https://t.me/itevents_team"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TgIcon />
                  </a>
                </li>
                <li className={classNames(s.socials__item, s.socials__itemYb)}>
                  <a
                    className={s.socials__link}
                    href="https://www.youtube.com/itevents"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
