import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import Contacts from '../Contacts';
import { DisciplineCard } from '../DisciplinesClassic';
import Footer from '../Footer';
import SubscribeForm from '../SubscribeForm';
import s from './DisciplinePage.module.scss';
import { ReactComponent as Arrow } from './images/arrow.svg';
import { ReactComponent as ArrowRight } from './images/arrowRight.svg';

export default function DisciplinePage(props: {
    card: DisciplineCard;
    cyberDisciplinesCards: DisciplineCard[];
    mainPath: string;
}) {
    const { card, cyberDisciplinesCards, mainPath } = props;
    const index = cyberDisciplinesCards.findIndex((c) => c.id === card.id);
    const activeCases = cyberDisciplinesCards.filter((c) => !c.disabled);
    const casesLen = activeCases.length;

    const navigate = useNavigate();
    const location = useLocation();
    return (
        <section className={classNames('section')}>
            <div className={classNames('wrapper', s.wrapper)}>
                <div className={s.buttons}>
                    <div
                        className={s.button}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <Arrow style={{ marginRight: '29px' }} /> СМОТРЕТЬ ВСЕ КЕЙСЫ
                    </div>
                    <a
                        href="https://formsports.worksportbalance.com/"
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(s.button, s.buttonGreen)}
                    >
                        ЗАПОЛНИТЬ БРИФ И <br />РАССЧИТАТЬ СТОИМОСТЬ
                    </a>
                    <div
                        className={s.button}
                        onClick={() => {
                            const nexElem = index + 1;
                            if (nexElem < casesLen) {
                                const link = `/${mainPath}/${cyberDisciplinesCards[nexElem].id}`;
                                navigate(link, { replace: true });
                            } else {
                                const link = `/${mainPath}/${cyberDisciplinesCards[0].id}`;
                                navigate(link, { replace: true });
                            }
                        }}
                    >
                        СМОТРЕТЬ СЛЕДУЮЩИЙ КЕЙС <ArrowRight style={{ marginLeft: '19px' }} />
                    </div>
                </div>
                <div className={s.titleContainer}>
                    <div className={s.filter}></div>
                    <div className={s.blockBg}>
                        <img src={card.bg} alt="" className={s.blockImg} />
                    </div>
                    <div className={s.titleWrapper}>
                        <div className={s.iconWrapper}>{card.icon}</div>
                        <h2 className={s.title}>{card.title}</h2>
                    </div>
                </div>
                {card.cardContent}
            </div>
            <SubscribeForm />
            <Contacts />
            <Footer />
        </section>
    );
}
