import { ReactComponent as ResolutionIcon1080 } from '../images/icons/1080_30fps.svg';
import { ReactComponent as ResolutionIcon30 } from '../images/icons/4k30fps.svg';
import { ReactComponent as ResolutionIcon60 } from '../images/icons/4k60fps.svg';
import { ReactComponent as BulbIcon } from '../images/icons/bulb.svg';
import { ReactComponent as CameraIcon } from '../images/icons/camera.svg';
import { ReactComponent as KeyIcon } from '../images/icons/key.svg';
import { ReactComponent as MembersIcon } from '../images/icons/members.svg';
import { ReactComponent as MicIcon } from '../images/icons/mic.svg';
import { ReactComponent as PalletteIcon } from '../images/icons/pallette.svg';
import { ReactComponent as PeoplesIcon } from '../images/icons/peoples.svg';
import { ReactComponent as PlusIcon } from '../images/icons/plus.svg';
import { ReactComponent as ProdIcon } from '../images/icons/postproduction.svg';
import { ReactComponent as StreamIcon } from '../images/icons/Streams.svg';
import { ReactComponent as TowerIcon } from '../images/icons/tower.svg';
import conf3 from '../images/pics/conf1.jpg';
import conf1 from '../images/pics/conf2.jpg';
import conf4 from '../images/pics/conf3.jpg';
import conf2 from '../images/pics/conf4.jpg';
import csgo1 from '../images/pics/csgo1.jpg';
import csgo2 from '../images/pics/csgo2.jpg';
import csgo3 from '../images/pics/csgo3.png';
import csgo4 from '../images/pics/csgo4.jpg';
import dota1 from '../images/pics/dota1.jpg';
import dota2 from '../images/pics/dota2.jpg';
import dota3 from '../images/pics/dota3.jpg';
import dota4 from '../images/pics/dota4.jpg';
import meetup1 from '../images/pics/meetup1.jpg';
import meetup2 from '../images/pics/meetup2.jpg';
import meetup3 from '../images/pics/meetup3.jpg';
import meetup4 from '../images/pics/meetup4.jpg';
import podcast1 from '../images/pics/podcast1.jpg';
import podcast2 from '../images/pics/podcast2.jpg';
import podcast3 from '../images/pics/podcast3.jpg';
import podcast4 from '../images/pics/podcast4.jpg';
import cart1 from '../images/pics/race1.jpg';
import cart2 from '../images/pics/race2.jpg';
import cart3 from '../images/pics/race3.jpg';
import cart4 from '../images/pics/race4.jpg';
import radio1 from '../images/pics/radio1.jpg';
import radio2 from '../images/pics/radio2.jpg';
import radio3 from '../images/pics/radio3.jpg';
import radio4 from '../images/pics/radio4.jpg';

export interface SlideStream {
    title: string;
    subTitle: string;
    rows: { icon: JSX.Element; text: JSX.Element }[];
    pics: string[];
    url?: string;
    urlBtnTitle?: string;
}

export const slides: SlideStream[] = [
    {
        title: 'Конференция',
        subTitle: 'HR API 2022',
        rows: [
            { icon: <CameraIcon />, text: <></> },
            { icon: <MicIcon />, text: <span>2 ручных и 2 головных микрофона в каждом зале</span> },
            { icon: <BulbIcon />, text: <span>Профессиональное освещение сцены</span> },
            { icon: <ResolutionIcon30 />, text: <span>Высокое качество трансляции 4k 30fps</span> },
            { icon: <KeyIcon />, text: <span>Трансляция на закрытый портал</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: (
                    <span>
                        Эфирная команда: 3 режиссера, 6 операторов, 6 помощников режиссера, 1
                        световик, 1 звукорежиссер
                    </span>
                ),
            },
        ],
        pics: [conf1, conf2, conf3, conf4],
        urlBtnTitle: 'HR API на youtube',
        url: 'https://www.youtube.com/@HRAPI',
    },
    {
        title: 'КИБЕРТУРНИР / LAN',
        subTitle: 'SELECTEL STUDENT CYBER CUP 2022 / DOTA 2',
        rows: [
            { icon: <StreamIcon />, text: <span>2 параллельных потока трансляции</span> },
            { icon: <MicIcon />, text: <span>2 комментатора на каждом сервере</span> },
            { icon: <CameraIcon />, text: <span>3 видеокамеры на площадке кибер-арены</span> },
            { icon: <ResolutionIcon60 />, text: <span>Высокое качество трансляции 4k 60fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: (
                    <span>
                        Эфирная команда: 3 режиссера, 2 обсервера,
                        <br /> 3 видеооператора
                    </span>
                ),
            },
        ],
        pics: [dota1, dota2, dota3, dota4],
        urlBtnTitle: 'Смотреть запись трансляции - server 1',
        url: 'https://www.youtube.com/live/v7ah-vzHhQc',
    },
    {
        title: 'СПОРТ',
        subTitle: 'Турнир по картингу «IT Race 2021»',
        rows: [
            { icon: <CameraIcon />, text: <span>4 видеокамеры</span> },
            { icon: <MicIcon />, text: <span>2 комментатора</span> },
            { icon: <ResolutionIcon1080 />, text: <span>Качество трансляции 1080p 30fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: (
                    <span>
                        Эфирная команда: 2 режиссера, 4 видеооператора, 2 помощника режиссера
                    </span>
                ),
            },
        ],
        pics: [cart1, cart2, cart3, cart4],
        urlBtnTitle: 'Смотреть запись трансляции на youtube',
        url: 'https://www.youtube.com/live/5rvVH1426wc',
    },
    {
        title: 'подкаст',
        subTitle: 'HR подкаст «НЕ HRAPI»',
        rows: [
            { icon: <CameraIcon />, text: <span>1-3 видеокамеры</span> },
            { icon: <MembersIcon />, text: <span>2-4 участника (online/offline)</span> },
            { icon: <ResolutionIcon60 />, text: <span>Качество трансляции 4k 60fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: <span>Эфирная команда: 1 режиссер, 1 помощник режиссера</span>,
            },
            {
                icon: <ProdIcon />,
                text: <span>Постпродакшн и публикация на основных подкаст ресурсах</span>,
            },
        ],
        pics: [podcast1, podcast2, podcast3, podcast4],
        urlBtnTitle: 'Плейлист  подкаста на youtube',
        url: 'https://youtube.com/playlist?list=PLp2aUaz0EAxCZaCoFjdwlewrFI4323W05',
    },
    {
        title: 'ДЕНЬ РАДИО',
        subTitle: 'VEEAM RADIO',
        rows: [
            { icon: <CameraIcon />, text: <span>3 видеокамеры</span> },
            { icon: <MembersIcon />, text: <span>3-4 участника (online/offline)</span> },
            { icon: <ResolutionIcon1080 />, text: <span>Качество трансляции 1080р 30fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: (
                    <span>
                        Эфирная команда: 1 режиссер, 1 помощник режиссера, 1 DJ, 1 звукорежиссер, 1
                        координатор эфира
                    </span>
                ),
            },
            {
                icon: <ProdIcon />,
                text: <span>Постпродакшн</span>,
            },
        ],
        pics: [radio1, radio2, radio3, radio4],
    },
    {
        title: 'КИБЕРТУРНИР / online',
        subTitle: 'ЮMoney CS:GO Tournament 2022',
        rows: [
            { icon: <CameraIcon />, text: <span>2 параллельных трансляции</span> },
            { icon: <ResolutionIcon30 />, text: <span>Качество трансляции 4k 60fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальное графическое офрмление</span> },
            { icon: <PalletteIcon />, text: <span>Кастомный игровой HUD</span> },
            { icon: <PlusIcon />, text: <span>Повторы, хайлайты</span> },
            {
                icon: <PeoplesIcon />,
                text: (
                    <span>
                        Эфирная команда: 2 комментатора, 2 режиссера трансляции, 2 обсервера
                    </span>
                ),
            },
        ],
        pics: [csgo1, csgo2, csgo3, csgo4],
        urlBtnTitle: 'Запись трансляции',
        url: 'https://www.youtube.com/live/vGetKvQ21B0',
    },
    {
        title: 'meetup',
        subTitle: 'veeam дискуссия',
        rows: [
            { icon: <CameraIcon />, text: <span>3 видеокамеры</span> },
            { icon: <MembersIcon />, text: <span>5 участников offline и 1 online</span> },
            { icon: <ResolutionIcon60 />, text: <span>Качество трансляции 4k 60fps</span> },
            { icon: <PalletteIcon />, text: <span>Индивидуальный дизайн проекта</span> },
            {
                icon: <PeoplesIcon />,
                text: <span>Эфирная команда: 1 режиссер, 1 помощник режиссера</span>,
            },
            {
                icon: <ProdIcon />,
                text: <span>Постпродакшн</span>,
            },
        ],
        pics: [meetup1, meetup2, meetup3, meetup4],
        urlBtnTitle: 'Смотреть запись на youtube',
        url: 'https://www.youtube.com/live/V9g3ThAvQzM',
    },
];
