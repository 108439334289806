import classNames from 'classnames';

import s from './Equipment.module.scss';
import cam from './images/camera.png';
import mic from './images/mic.png';
import midi from './images/midi.png';
import mixer from './images/mixer.png';
import server from './images/server.png';
import soft from './images/SOFT.png';

export function Equipment() {
    return (
        <section className={classNames('section', s.section)}>
            <div className="wrapper">
                <div className="anchor-place" id="equipment"></div>
                <h2 className={classNames('section__title', s.sectionTitle)}>НАШЕ ОБОРУДОВАНИЕ</h2>
                <ul className={s.blocks}>
                    {/* Вещательное оборудование */}
                    <div className={s.block}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>Вещательное оборудование</div>
                            <div className={s.textTitle}>Графическая станция</div>
                            <div className={s.textBlock}>
                                В основе наших графических станций — мощное железо, позволяющее показывать
                                картинку в очень высоком качестве 4К60fps. Это
                                18 ядерный процессор{' '}
                                <span className={s.textBold}>Intel 10980XE</span> на 
                                <span className={s.textBold}> HEDT-чипсете x299</span>, твердотельные накопители профессионального уровня{' '}
                                <span className={s.textBold}>Samsung 970 PRO</span>, топовая
                                видеокарта
                                <span className={s.textBold}> Asus Strix RTX 3090</span> с 24gb. Такой комплект даёт
                                практически неограниченные возможности графического оформления
                                трансляций: стингеры и переходы, огромное количество оверлеев и
                                видеороликов, повторы и хайлайты, очень тяжелые и сложные анимации.
                            </div>
                            <div className={s.blockDivider}></div>
                            <div className={s.textTitle}>Видео-энкодер</div>
                            <div className={s.textBlock}>
                                Отличное качество картинки достигается за счет перераспределения мощностей. 
                                Кодирует и отправляет стрим видео-энкодер:{' '}
                                <span className={s.textBold}>Blackmagic Web Presenter 4k.</span> Его
                                аппаратная начинка профессионального уровня кодирует поток данных
                                вплоть до 4К60fps кодеком H.264 и отправляет на стриминговую
                                платформу. Еще одно важное достоинство этого устройства — мониторинг
                                технических параметров. Оно проверяет входящий видеосигнал, громкость звука,
                                состояние эфира и скорость передачи данных, а также степень заполнения
                                кэш-памяти и прочую служебную информацию.
                            </div>
                        </div>
                        <div className={s.picture} style={{ width: '44%' }}>
                            <img src={server} alt="" className={s.blockImg} />
                        </div>
                    </div>
                    {/* МИКРОФОНЫ */}
                    <div className={classNames(s.block, s.blockLeftPic)}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>МИКРОФОНЫ</div>
                            <div className={s.textTitle}>Для подкастов и интервью “за столом”</div>
                            <div className={s.textBlock}>
                                Профессиональный динамический микрофон 
                                <span className={s.textBold}> Shure SM7b</span> в связке с одним
                                из лучших микрофонных предусилителей
                                <span className={s.textBold}> Cloudlifter CL-2</span> дают
                                идеальный звук для подкастов и интервью “за столом”. Именно эту
                                связку используют топовые студии звукозаписи, а также блогеры для записи подкастов.
                            </div>
                            <div className={s.textTitle}>Для “подвижных” форматов</div>
                            <div className={s.textBlock}>
                                Для “подвижных” форматов мы используем беспроводную радиосистему
                                <span className={s.textBold}> Sennheiser ew 112</span> в связке с петлицами 
                                <span className={s.textBold}> Sennheiser ME-2 / ME-4</span> или с
                                оголовьем телесного цвета <span className={s.textBold}> Sennheiser HSP 4-EW-3. </span>
                                Петличные микрофоны менее заметны в кадре, но захватывают больше посторонних шумов.
                                Микрофоны-оголовье более заметны в кадре, но с их помощью мы получаем
                                идеальный звук, даже при поворотах головы.
                            </div>
                            <div className={s.textTitle}>Для публичных выступлений</div>
                            <div className={s.textBlock}>
                                Классический вариант — ручные микрофоны представлены серией
                                <span className={s.textBold}> Sennheiser ew 112.</span> Это надежное решение,
                                отлично показавшее себя в эксплуатации. Беспроводная поясная радиосистема 
                                <span className={s.textBold}> Sennheiser ew 112</span> в связке с оголовьем
                                телесного цвета <span className={s.textBold}> Sennheiser HSP 4-EW-3 </span>
                                дают чистейший звук и оставляют руки свободными при выступлениии на
                                конференциях и митапах.
                            </div>
                            
                        </div>
                        <div className={s.picture} style={{ width: '24%' }}>
                            <img src={mic} alt="" className={s.blockImg} />
                        </div>
                    </div>
                    {/* МИКШЕРЫ */}
                    <div className={classNames(s.block)}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>МИКШЕРЫ</div>
                            <div className={s.textBlock}>
                                Микшерный пульт <span className={s.textBold}>Zoom L12 </span>
                                — многофункциональная цифровая консоль для
                                микширования, звукозаписи, мониторинга и воспроизведения. Идеально
                                подходит для многоканальной записи звука (до 12 каналов), подкастов
                                (каждый участник получит в наушники комфортную ему громкость
                                остальных участников) или при проведении онлайн и оффлайн-конференций,
                                выступая в роли внешней звуковой карты.
                            </div>
                            <div className={s.textBlock}>
                                На больших спортивных мероприятиях и конференциях для управления
                                звуком на площадке мы дополнительно используем цифровой микшер
                                <span className={s.textBold}> YAMAHA MG12XU</span> или аналоговый микшер
                                <span className={s.textBold}> Soundcraft EPM8.</span>
                            </div>
                        </div>
                        <div className={s.picture} style={{ width: '36%' }}>
                            <img src={mixer} alt="" className={s.blockImg} />
                        </div>
                    </div>
                    {/* МИДИ-клавиатуры */}
                    <div className={classNames(s.block, s.blockLeftPic)}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>МИДИ-клавиатуры</div>
                            <div className={s.textBlock}>
                            MIDI-клавиатура <span className={s.textBold}>AKAI PRO MPX16 </span>
                            — компактный семплер для ведущих развлекательных шоу и подкастов, 
                            с помощью которого можно включить аплодисменты, барабанную дробь, 
                            квакающий смех или любые другие заранее запрограммированные звуки.
                            </div>
                            <div className={s.textBlock}>
                            MIDI-клавиатура <span className={s.textBold}> AKAI PRO APC mini </span>
                            — главный инструмент режиссера прямого эфира. С помощью этой клавиатуры
                            он полностью управляет звуком и картинкой эфира.
                            </div>
                            <div className={s.textBlock}>
                            Контроллер <span className={s.textBold}>ShuttlePRO v2 </span>
                            от <span className={s.textBold}>Contour Design </span>
                            — с помощью этого девайса режиссер управляет повторами на спортивных
                            и киберспортивных трансляциях.
                            </div>
                        </div>
                        <div className={s.picture} style={{ width: '30%' }}>
                            <img src={midi} alt="" className={s.blockImg} />
                        </div>
                    </div>
                    {/* ВИДЕОКАМЕРЫ и ШТАТИВЫ */}
                    <div className={classNames(s.block)}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>ВИДЕОКАМЕРЫ и ШТАТИВЫ</div>
                            <div className={s.textBlock}>
                            <span className={s.textBold}>Blackmagic Studio Camera 4K Pro</span> — одна из лучших современных
                            студийных камер для прямого эфира и записи. Матрица с разрешением 3840x2160 превосходно подходит
                            для проектов в HD и Ultra HD. Сочетание 4K-сенсора и цветокодировки 5-го поколения позволяет получать
                            эфирное изображение кинематографического качества, как на цифровых кинокамерах.
                            </div>
                            <div className={s.textBlock}>
                            В паре с камерой мы используем объективы <span className={s.textBold}>Olympus ED 12-40mm f/2.8 Pro </span>
                            (для студий) и <span className={s.textBold}>Olympus ED 40-150mm f/2.8 Pro </span> (для конференц-залов).
                            Объективы этой серии отличаются светлой, детализированной и очень сочной картинкой.
                            </div>
                            <div className={s.textBlock}>
                            В качестве штатива мы используем <span className={s.textBold}>Manfrotto 501 HDV / 525 MVB</span> —
                            надёжный алюминиевый сплав,
                            возможность наклона головы влево-вправо +90°/-60°, плавные механизмы регулировки вертикального
                            панорамирования, устойчивая конструкция.
                            </div>
                        </div>
                        <div className={s.picture} style={{ width: '40%' }}>
                            <img src={cam} alt="" className={s.blockImg} />
                        </div>
                    </div>
                    {/* ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ */}
                    <div className={classNames(s.block, s.blockLeftPic)}>
                        <div className={s.textContentWrapper}>
                            <div className={s.blockTitle}>ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ</div>
                            <div className={s.textTitle}>Видеомикшеры</div>
                            <div className={s.textBlock}>
                            <span className={s.textBold}>vMix</span> — профессиональный программный видеомикшер для многокамерного
                            потокового вещания и записи видео. Имеет встроенные видеозвонки, почти неограниченные возможности
                            графического оформления, отлично справляется с задачами повторов, поддерживает современные протоколы
                            и многое другое.
                            </div>
                            <div className={s.textBlock}>
                            <span className={s.textBold}>OBS </span>(Open Broadcaster Software) — видеомикшер с открытым исходным кодом. Проект
                            создали, обновляют и поддерживают независимые разработчики. Имеет большое количество разнообразных
                            плагинов и дополнений.
                            </div>
                            <div className={s.textTitle}>Титровальная машина</div>
                            <div className={s.textBlock}>
                            <span className={s.textBold}>vMix GT Title Designer</span> — это профессиональное приложение, позволяющее сочетать 
                            заголовки, динамический текст и графику.
                            </div>
                            
                        </div>
                        <div className={s.picture} style={{ width: '50%' }}>
                            <img src={soft} alt="" className={s.blockImg} />
                        </div>
                    </div>
                </ul>
            </div>
        </section>
    );
}
