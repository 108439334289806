import classNames from 'classnames';
import { useState } from 'react';

import { ReactComponent as TgIcon } from '../../sharedImages/telegram_icon.svg';
import { ReactComponent as VkIcon } from '../../sharedImages/vk_icon.svg';
import { ReactComponent as YoutubeIcon } from '../../sharedImages/youtube_icon.svg';
import s from './SubscribeForm.module.scss';

export default function SubscribeForm() {
    const [email, setEmail] = useState('');
    return (
        <section className={classNames('section', s.formSection)}>
            <div className="wrapper">
                <div className="anchor-place" id="contacts"></div>
                <div className={s.contents}>
                    <div className={classNames(s.block, s.contact)}>
                        <div className={s.title}>СВЯЖИТЕСЬ С НАМИ</div>
                        <a
                            className={s.briffButton}
                            href="https://forms.worksportbalance.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Заполните бриф - получите сценарий <br /> вашего спортивного праздника
                            бесплатно!
                        </a>
                        <div className={s.contactLinks}>
                            <a
                                href="tel:+79216457808"
                                className={classNames(s.linkBtn, s.phoneBtn)}
                            >
                                +7-921-645-78-08
                            </a>
                            <a
                                href="https://t.me/denrit"
                                className={classNames(s.linkBtn, s.tgBtn)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Telegram
                            </a>
                        </div>
                    </div>
                    <div className={classNames(s.block)}>
                        <div className={s.title}>ПОДПИШИТЕСЬ НА НОВОСТИ</div>
                        <div className={s.contentGrid}>
                            <div className={s.socialsBlock}>
                                <div className={s.hashTag}>#WorkSportBalance</div>
                                <div className={s.socialsItems}>
                                    <a
                                        className={s.socialsItem}
                                        href="https://vk.com/worksportbalance?from=group"
                                        target=" _blank"
                                        rel="noreferrer"
                                    >
                                        <VkIcon />
                                    </a>
                                    <a
                                        className={s.socialsItem}
                                        href="https://www.youtube.com/channel/UCEUVRUvr1yw6d3jbkRaHbdg"
                                        target=" _blank"
                                        rel="noreferrer"
                                    >
                                        <YoutubeIcon />
                                    </a>
                                    <a
                                        className={s.socialsItem}
                                        href="https://t.me/WSB_eSports"
                                        target=" _blank"
                                        rel="noreferrer"
                                    >
                                        <TgIcon />
                                    </a>
                                </div>
                            </div>
                            <form
                                method="POST"
                                className={s.form}
                                action="https://cp.unisender.com/ru/subscribe?hash=6kkyupnt5fy8jjdg3cbxkchp9ohms8fmm99147cdwuroakun7pm4y"
                                name="subscribtion_form"
                            >
                                <div className="subscribe-form-item subscribe-form-item--input-email">
                                    <input
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                        className="subscribe-form-item__control subscribe-form-item__control--input-email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        placeholder="Введите Ваш e-mail"
                                    />
                                </div>
                                <div className="subscribe-form-item subscribe-form-item--btn-submit">
                                    <input
                                        className={classNames(
                                            'subscribe-form-item__btn subscribe-form-item__btn--btn-submit',
                                            s.button,
                                        )}
                                        type="submit"
                                        value="Подписаться"
                                    />
                                </div>
                                <input type="hidden" name="charset" value="UTF-8" />
                                <input type="hidden" name="default_list_id" value="20623418" />
                                <input type="hidden" name="overwrite" value="2" />
                                <input type="hidden" name="is_v5" value="1" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
